/******************************************
    Header
******************************************/

.header {
    position: fixed;
    top: 0;
    width: 100%;
    padding-block: 16px;
    background: $color-white;
    box-shadow: $shadow-xl;
    z-index: 1000;

    &__logo {
        img { height: 32px; }
        z-index: 1000;
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__nav {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: rgba($color-white, .85);
        backdrop-filter: blur(10px);
        opacity: 0;
        display: flex;
        transform: translateY(100%);
        transition: .5s ease all;
        justify-content: center;
        align-items: center;

        @include bp(lg) {
            display: block;
            opacity: 1;
            transform: none;
            position: relative;
            top: auto;
            left: auto;
            background-color: transparent;
            backdrop-filter: none;
            height: auto;
            width: auto;
        }   

        &--open {
            opacity: 1;
            transform: translateY(0%);
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            text-align: center;

            li {
                margin-block: 16px;

                @include bp(lg) {
                    display: inline-block;
                    margin: 0 12px;
                }

                a {
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    text-decoration: none;
                    color: $color-primary;
                    font-weight: 600;
                    font-size: 20px;

                    @include bp(lg) {
                        font-size: 16px;
                        font-weight: 600;
                    }

                    &:hover { color: lighten($color-primary, 20%); }
                }
            }
        }
    }

    &__burger {
        position: relative;
        //margin-bottom: -8px;
        margin-right: -12px;
        width: 24px;
        height: 12px;
        justify-content: flex-end;
        cursor: pointer;
        border: 12px solid transparent;
        box-sizing: content-box;
        z-index: 110;

        @include bp(lg) {
            display: none;
        }   

        &__line {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            height: 2px;
            background: $color-primary;
            transition: .3s ease-in-out all;
            width: 100%;
            
            &--1 { top: 0; }
            
            &--2 {
                display: none;
                top: 50%;
                transform: translateY(-50%);
            }
    
            &--3  {
                bottom: 0;
                width: 70%;
            }
        }

        &--active {
            .header__burger__line--1 {
                transform: rotate(-45deg);
                top: 50%;
            }
        
            .header__burger__line--2 { display: none; }
            
            .header__burger__line--3 {
                transform: rotate(45deg);
                width: 100%;
                top: 50%;
                bottom: auto;
            }
        }
    }
}