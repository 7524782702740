/******************************************
    Heading
******************************************/

.heading {
    &__skyline {
        margin-bottom: 5px;
        font-size: 14px;
        color: $color-grey-4;
        text-transform: uppercase;
        letter-spacing: 1.5px;

        @include bp(md) { font-size: 16px; }
        @include bp(xl) { font-size: 19px; }

        .hero & {
            //color: $color-secondary;
            font-weight: 600;
        }
    }
}


/******************************************
    Button
******************************************/

.btn {
    margin-top: 16px;
    background-color: $color-primary;
    border-radius: 0;
    color: $color-white;
    font-size: 17px;
    font-weight: 500;
    padding: .8rem 1.3rem;

    @include bp(lg) {
        padding: 1.5rem 2.5rem;
        font-size: 19px;
    }

    &:hover { background-color: lighten($color-primary, 10%); color: $color-white; }
    &:active, &:focus { color: $color-white; }
}



/******************************************
    Quote
******************************************/

.blockquote {
    position: relative;
    //border-left: 3px solid $color-grey-2;
    // padding: 30px;
    // background: $color-grey-1;

    // @include bp(md) { padding: 45px; }

    p {
        //font-style: italic;
        font-size: 21px;
        color: $color-primary;

        &:before, &:after {
            //font-size: 56px;
            color: $color-primary;
            opacity: .5;
        }
    
        &:before {
            content: '\00BB';
            margin-left: -16px;

            @include bp(md) { margin-left: -18px; }
        }
       
        &:after { content: '\00AB'; }

        @include bp(md) { font-size: 24px; }
        @include bp(lg) { font-size: 28px; }
       // @include bp(xl) { font-size: 32px; }
    }
}



/******************************************
    Accordion
******************************************/

.accordion {
    &-item { background-color: transparent; }

    &-button {
        outline: none;
        color: $color-primary;
        font-weight: 400;
        font-size: 18px;
        padding: 1.25rem;
        gap: 12px;
        background-color: transparent;

        @include bp(lg) { font-size: 20px; }
        @include bp(xl) { padding: 1.5rem; font-size: 22px; }

        &:not(.collapsed), &:hover {
            background-color: lighten($color-primary, 70%);
            color: $color-primary;
            box-shadow: none;
        }

        &:focus { box-shadow: none; }

        strong {
            align-self: flex-start;
        }
    }
}



/******************************************
    Downlaod Link
******************************************/

.download-link {
    display: flex;
    font-size: 18px;
    text-decoration: none;
    line-height: 1.4;
    margin-block: 30px;

    &:hover { text-decoration: underline; }

    @include bp(md) { font-size: 22px; }
    @include bp(xl) { font-size: 24px; }

    &:before {
        content: '';
        display: block;
        flex: 0 0 auto;
        width: 24px;
        height: 24px;
        margin-right: 8px;
        background: url('../img/icon-download.svg') no-repeat center center;
        background-size: contain;

        @include bp(md) { width: 32px; height: 32px; margin-right: 12px; }
        @include bp(xl) { width: 32px; height: 32px; }
    }
}


/******************************************
    List
******************************************/

.check-list {
    counter-reset: check-list-counter;
    padding-left: 0;
    margin-bottom: 0;

    li {
        display: flex;
        position: relative;
        font-size: 120%;
        color: $color-grey-5;
        margin-block: 24px;
        line-height: 1.5;
        list-style: none;
        counter-increment: check-list-counter;

        &:before {
            content: counter(check-list-counter);
            display: block;
            height: 28px;
            width: 28px;
            border: 1px solid $color-grey-2;
            margin-right: 16px;
            flex: 0 0 auto;
            font-size: 75%;
            color: $color-grey-2;
            text-align: center;
            line-height: 27px;

            @include bp(md) {
                height: 32px;
                width: 32px;
                line-height: 31px;
            }
        }
    }
}

.check-list-info {
    @include bp(md) {
        padding-inline: 6rem;
    }
}


.number-list {
    li { margin-bottom: 4px; }
}



/******************************************
    Info Icon
******************************************/

.info-icon {
    height: 40px;
    width: 40px;
    background: url('../img/icon-info.svg') no-repeat center center;
    background-size: contain;
    flex: 0 0 auto;

    &--white {  background-image: url('../img/icon-info-white.svg') }

    @include bp(md) { width: 48px; height: 48px; margin-right: 12px; }
    @include bp(xl) { width: 56px; height: 56px; margin-right: 16px; }
}



/******************************************
    YouTube Video
******************************************/

.youtube-video {
    
    &__consent {
        z-index: 1000;
        position: absolute;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $color-primary;
        padding: 0 30px;

        @include bp(md) { padding: 0 45px; }
        //@include bp(xl) { padding: 90px; }

        &:before {
            @include bp(md) {
                content: '';
                height: 64px;
                width: 64px;
                background: url('../img/icon-play-white.svg') no-repeat center center;
                background-size: contain;
                margin-bottom: 24px;
            }
        }

        p {
            color: $color-white;
            text-align: center;

            a { color: $color-white; }
        }

        &__btn {
            background-color: $color-secondary;
            color: $color-white;
            padding: 1rem 2rem;

            &:hover { background-color: darken($color-secondary, 10%); }
        }
    }
}



/******************************************
    Modal
******************************************/

.modal-header {
    button.close {
        padding: 1rem;
        -webkit-appearance: none;
        appearance: none;
        border: 0;
        background: none;
        font-size: 28px;
        line-height: 1;
    }
}