/***************************
	Vertical Paddings 
***************************/

$vspacing-values : (
	1,
	2,
	3,
	5,
	7,
	10,
	15
) !default;

$vspacing-values-mobile : (
	'1': 5,
	'2': 6,
	'3': 8,
	'5': 11,
	'7': 13,
	'10': 15,
	'15': 22
) !default;

$vspacing-values-xxl : (
	'1': 25,
	'2': 39,
	'3': 54,
	'5': 85,
	'7': 110,
	'10': 154,
	'15': 226
) !default;


$vspacing-prefixes : (
	vpt : padding-top,
	vpb : padding-bottom,  
	vmt : margin-top,
	vmb : margin-bottom
) !default;



@mixin make-vspacings() {
	@each $attr-short, $attr-long in $vspacing-prefixes { 
		@each $value in $vspacing-values {
			.#{$attr-short}-#{$value} {
				#{$attr-long}: #{$value}#{'vw'};
			}
		}
	}
}

@mixin make-vspacings-mobile() {
	@each $attr-short, $attr-long in $vspacing-prefixes { 
		@each $name, $value in $vspacing-values-mobile {
			.#{$attr-short}-#{$name} {
				#{$attr-long}: #{$value}#{'vw'};
			}
		}
	}
}

@mixin make-vspacings-xxl() {
	@each $attr-short, $attr-long in $vspacing-prefixes { 
		@each $name, $value in $vspacing-values-xxl {
			.#{$attr-short}-#{$name} {
				#{$attr-long}: #{$value}#{'px'};
			}
		}
	}
}

@include make-vspacings-mobile();

@include bp(sm) { 
	@include make-vspacings();
}

@include bp(xxl) { 
	@include make-vspacings-xxl();
}



/***************************
	Padding & Margin
***************************/

$space-values : (
	0,
	10,
	20,
	30,
	40,
	50,
	60,
	70,
	80,
	90,
	100,
	110,
	120,
	130,
	140,
	150
) !default;

$space-prefixes : (
p  : padding,
pt : padding-top,
pr : padding-right,
pb : padding-bottom,
pl : padding-left,
m  : margin,
mt : margin-top,
mr : margin-right,
mb : margin-bottom,
ml : margin-left,
) !default;


@mixin make-spaces() {
// Use Breakpoints-Map from _breakpoints.scss
	@each $name-bp, $value-bp in $breakpoints {
		@media (min-width: $value-bp) {
			@each $attr-short, $attr-long in $space-prefixes { 
				@each $value in $space-values {     
					.#{$attr-short}#{$value}-#{$name-bp} {
						#{$attr-long}: #{$value}#{'px'} !important;
					}
				}
			}
		}
	}
}

@include make-spaces();



/***************************
	Hiding
***************************/

.hidden { display: none; }

.mobile-hidden {
	display: none ;
	@include bp(sm) { display: block; }
}

@include bp(sm) {  
	.desktop-hidden { display: none; } 
}

.hide-text {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	white-space: nowrap; /* 1 */
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
	white-space: inherit;
}

.invisible {
	visibility: hidden;
}



/***************************
	Clearfix
***************************/

.clearfix:before,
.clearfix:after {
	content: " ";
	display: table;
}

.clearfix:after {
	clear: both;
}