.footer {
    background-color: $color-grey-5;
    color: $color-white;

    &__copyright {
        color: $color-grey-1;
        font-weight: 400;
        font-size: 14px;
    }

    &__nav {
        margin: 0;
        padding: 0;
        list-style: none;

        @include bp(md) { text-align: right; }

        li {
            display: block;
            margin: 3px 0;
            font-size: 14px;

            @include bp(lg) {
                display: inline-block;
                margin-left: 20px;
            }

            a {
                color: $color-white;
                text-decoration: none;

                &:hover { text-decoration: underline; }
            }
        }
    }
}